/* This file was automaticaly generated by the DFS worker. Changes here will be overwritten. 
 To do change edit the config.json file! */ 

$imagePath: "../images/" !default;

$baseFontSize: 18px;

$baseLineHeight: 28px;

$h1Size: 40px;

$h2Size: 30px;

$h3Size: 25px;

$h4Size: 24px;

$h5Size: 22px;

$h6Size: 18px;

$mainFont: Barlow, sans-serif;

$displayFont: "Barlow", sans-serif;

$iconFont: "icomoon", sans-serif;

// Project colors

$primary: #ec1745;
$secondary: #fad41f;
$third: #00a9a3;
$primaryLight: #fde9e3;
$secondaryLight: #fff6d6;
$thirdLight: #aedfde;
$light: #fff;
$medium: #F2F2F0;
$dark: #212121;
$border: #f3f3f3;
$alert: #D85D00;

$baseGap: 18px;

$rowMaxWidth: 1620px;

$columnCount: 12;

$baseBorder: 1px solid $border;

$outline: 1px dotted $alert;

$bpContext: 16px;

$hideTextDirection: right;

// Project iconMap

$iconMap: (
	times: "\f00e", 
	minus: "\f068", 
	angle-right: "\f054", 
	plus: "\f067", 
	angle-up: "\f077", 
	exclamation: "\f06a", 
	arrow-down: "\ea1d", 
	clock: "\e968", 
	mail: "\e9b0", 
	phone: "\ea81", 
	camera: "\e942", 
	play: "\ea29", 
);

$bgIcons: (
	blackStar: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NCIgaGVpZ2h0PSI2NCIgdmlld0JveD0iMCAwIDY0IDY0Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsLXJ1bGU6IGV2ZW5vZGQ7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDxwYXRoIGlkPSJTdGVybl9taXRfNV9aYWNrZW5fMSIgZGF0YS1uYW1lPSJTdGVybiBtaXQgNSBaYWNrZW4gMSIgY2xhc3M9ImNscy0xIiBkPSJNMzItLjA0NEw0Mi41NDYsMjAuMDgyLDY0LDI0LjQxOCw0OS4wNjQsNDEuMTkzLDUxLjc3NSw2NCwzMiw1NC4yMzksMTIuMjI1LDY0bDIuNzEtMjIuODA2TDAsMjQuNDE4bDIxLjQ0OS00LjMzNkwzMi0uMDQ0Ii8+Cjwvc3ZnPgo=", 
	blackTimes: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NCIgaGVpZ2h0PSI2NCIgdmlld0JveD0iMCAwIDY0IDY0Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsLXJ1bGU6IGV2ZW5vZGQ7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDxwYXRoIGlkPSJSZWNodGVja18xIiBkYXRhLW5hbWU9IlJlY2h0ZWNrIDEiIGNsYXNzPSJjbHMtMSIgZD0iTTUxLjc1MSw2LjVMNTcuNSwxMi4yNDksMTIuMjQ5LDU3LjUsNi41LDUxLjc1MSw1MS43NTEsNi41Ii8+CiAgPHBhdGggaWQ9IlJlY2h0ZWNrXzFfS29waWUiIGRhdGEtbmFtZT0iUmVjaHRlY2sgMSBLb3BpZSIgY2xhc3M9ImNscy0xIiBkPSJNNTcuNSw1MS43NTFMNTEuNzUxLDU3LjUsNi41LDEyLjI0OSwxMi4yNDksNi41LDU3LjUsNTEuNzUxIi8+Cjwvc3ZnPgo=", 
	blackCheck: "data:image/svg+xml;base64,PHN2ZyBpZD0iQ2FwYV8xIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTUuNTU2IDUxNS41NTYiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTE1LjU1NiA1MTUuNTU2IiB3aWR0aD0iNTEyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Im0wIDI3NC4yMjYgMTc2LjU0OSAxNzYuODg2IDMzOS4wMDctMzM4LjY3Mi00OC42Ny00Ny45OTctMjkwLjMzNyAyOTAtMTI4LjU1My0xMjguNTUyeiIvPjwvc3ZnPg==", 
	whiteStar: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NCIgaGVpZ2h0PSI2NCIgdmlld0JveD0iMCAwIDY0IDY0Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiAjZmZmOwogICAgICAgIGZpbGwtcnVsZTogZXZlbm9kZDsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPHBhdGggaWQ9IlN0ZXJuX21pdF81X1phY2tlbl8xIiBkYXRhLW5hbWU9IlN0ZXJuIG1pdCA1IFphY2tlbiAxIiBjbGFzcz0iY2xzLTEiIGQ9Ik0zMi0uMDQ0TDQyLjU0NiwyMC4wODIsNjQsMjQuNDE4LDQ5LjA2NCw0MS4xOTMsNTEuNzc1LDY0LDMyLDU0LjIzOSwxMi4yMjUsNjRsMi43MS0yMi44MDZMMCwyNC40MThsMjEuNDQ5LTQuMzM2TDMyLS4wNDQiLz4KPC9zdmc+Cg==", 
	whiteTimes: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NCIgaGVpZ2h0PSI2NCIgdmlld0JveD0iMCAwIDY0IDY0Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiAjZmZmOwogICAgICAgIGZpbGwtcnVsZTogZXZlbm9kZDsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPHBhdGggaWQ9IlJlY2h0ZWNrXzEiIGRhdGEtbmFtZT0iUmVjaHRlY2sgMSIgY2xhc3M9ImNscy0xIiBkPSJNNTEuNzUxLDYuNUw1Ny41LDEyLjI0OSwxMi4yNDksNTcuNSw2LjUsNTEuNzUxLDUxLjc1MSw2LjUiLz4KICA8cGF0aCBpZD0iUmVjaHRlY2tfMV9Lb3BpZSIgZGF0YS1uYW1lPSJSZWNodGVjayAxIEtvcGllIiBjbGFzcz0iY2xzLTEiIGQ9Ik01Ny41LDUxLjc1MUw1MS43NTEsNTcuNSw2LjUsMTIuMjQ5LDEyLjI0OSw2LjUsNTcuNSw1MS43NTEiLz4KPC9zdmc+Cg==", 
	whiteCheck: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NCIgaGVpZ2h0PSI2NCIgdmlld0JveD0iMCAwIDY0IDY0Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiAjZmZmOwogICAgICAgIGZpbGwtcnVsZTogZXZlbm9kZDsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPHBhdGggaWQ9IlJlY2h0ZWNrXzFfS29waWUiIGRhdGEtbmFtZT0iUmVjaHRlY2sgMSBLb3BpZSIgY2xhc3M9ImNscy0xIiBkPSJNMjIuNiw1NS4zMkw2NC4zMiwxMy42LDU4LjY2Myw3Ljk0NCwyMi42LDQ0LjAwNiw2LjMzNywyNy43NDMsMC42OCwzMy40WiIvPgo8L3N2Zz4K", 
);

// Project gridMap

$gridMap: (
	default: (
		width: em(0px, $bpContext), 
		cols: (6),
	),
	tiny: (
		width: em(480px, $bpContext), 
		center: (justify-content:center),
		cols: (6, 11),
	),
	small: (
		width: em(640px, $bpContext), 
		cols: (6, 10),
	),
	medium: (
		width: em(760px, $bpContext), 
		center: (justify-content:center),
		cols: (4, 6),
	),
	large: (
		width: em(992px, $bpContext), 
		cols: (4, 6, 8, 12),
		first: (order:-1),
		middle: (align-items:center),
	),
	giant: (
		width: em(1200px, $bpContext), 
		cols: (3, 9),
	),
	huge: (
		width: em(1364px, $bpContext), 
		cols: (2, 3, 4, 5, 10),
		middle: (align-items:center),
		prefix: (1),
		suffix: (1),
	),
	full: (
		width: em(1620px, $bpContext), 
	),
	cut: (
		width: em($rowMaxWidth, $bpContext), 
		cols: (auto),
	),
);

