
// HEADER
//////////////////////////////

#header {
	position: relative;
	width: 100%;

	.brandingMobil {
		display: block;
		max-width: 300px;
		width: 100%;
		height: auto;
		margin: 0 auto;
		position: relative;
		z-index: 1;
		padding: rem($baseGap*5) 0 rem($baseGap);
		
	}

	picture {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 0;
		width: 100%;
		height: auto;
	}
	@include breakpoint(large) {
		.brandingMobil {
			display: none;
		}
		padding-top: 270px;
	}
	@include breakpoint(giant) {
		padding-top: 170px;
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: flex;
	flex-wrap: wrap;
	.mainContent {
		width: 100%;
		.greyBg {
			background:$medium;
			&.margBot {
				margin-bottom:50px;
				@include breakpoint(giant) {
					margin-bottom:100px;
				}
			}
		}
		>.greyBg, >.row {
			padding:50px 0;
			@include breakpoint(giant) {
				padding:100px 0;
			}
		}
	}
	.teaser {
		body.index & {
			order:-1;
		}
		width: 100%;
		text-align: center;
		@include breakpoint(large) {
			position: relative;
			&:before {
				content:'';
				background:$medium;
				position: absolute;
				bottom: 0;
				width: 100%;
				height: 40%;
				left:0;
			}
		}
		.big {
			font-weight: 700;
			font-size: 20px;
			margin: 50px 0;
			display: block;
			body:not(.index) & {
				margin-top: 0;
			}
			.small {
				font-size: 16px;
				text-transform: uppercase;
				font-weight: 300;
				display: block;
			}
			@include breakpoint(small) {
				font-size: 30px;
				line-height: 40px;
				.small {
					font-size: 18px;
					line-height: 28px;
				}
			}
			@include breakpoint(giant) {
				margin: 100px 0 50px;
				font-size: 60px;
				line-height: 70px;
				.small {
					font-size: 25px;
					line-height: 35px;
				}
			}
		}
		.box {
			position: relative;
			border:10px solid $medium;
			text-align: center;
			margin-top: rem($baseGap);
			transition:0.3s;
			background: $light; 
			@include breakpoint(tiny) {
				margin-top: 0;
			}
			&:hover {
				&.red {
					border:10px solid $primary;
					span.headline {
						span.text {
							>span {
								color:$primary;
								display: block;
							}
						}
					}
				}
				&.yellow {
					border:10px solid $secondary;
					span.headline {
						span.text {
							>span {
								color:$secondary;
								display: block;
							}
						}
					}
				}
				&.blue {
					border:10px solid $third;
					span.headline {
						span.text {
							>span {
								color:$third;
								display: block;
							}
						}
					}
				}
				@include breakpoint(giant) {
					img {
						opacity: 0;
						visibility: hidden;
					}
				}
				span.headline {
					span.text {
						>span {
							display: block;
						}
						@include breakpoint(giant) {
							visibility: visible;
							opacity: 1;
						}
					}
				}
				@include breakpoint(huge) {
					span.headline {
						top:50px;
						span.text {
							margin-top: 10px;
						}
					}
				}
				@include breakpoint(full) {
					span.headline {
						top:30px;
					}
				}
			}
			&.red {
				border:10px solid $primaryLight;
				span.headline {
					color:$primary;
					@include breakpoint(huge) {
						font-size: 30px;
					}
				}
			}
			&.yellow {
				border:10px solid $secondaryLight;
				span.headline {
					color:$secondary;
					@include breakpoint(huge) {
						font-size: 30px;
					}
				}
			}
			&.blue {
				border:10px solid $thirdLight;
				margin-bottom: 50px;
				span.headline {
					color:$third;
					@include breakpoint(huge) {
						font-size: 30px;
					}
				}
				@include breakpoint(tiny) {
					margin-top: rem($baseGap*2);
				}
				@include breakpoint(medium) {
					margin-top: 0;
				}
				@include breakpoint(giant) {
					margin-bottom: 100px;
				}
			}
			span.headline {
				position: absolute;
				text-transform: uppercase;
				font-weight: 700;
				text-align: center;
				top:10px;
				transition:0.3s;
				span.text {
					opacity: 0;
					visibility: hidden;
					font-size: 18px;
					line-height: 28px;
					color:$dark;
					display: block;
					text-transform: none;
					font-weight: 300;
					transition:0.3s;
					@include breakpoint(huge) {
						margin-top: 30px;
					}
				}
				@include breakpoint(giant) {
					top:0;
					padding:rem($baseGap);
				}
				@include breakpoint(huge) {
					top:20px;
				}
				@include breakpoint(full) {
					top: -20px;
					padding:rem($baseGap*3);
				}
			}
			img {
				margin-top: 50px;
				@include breakpoint(giant) {
					opacity: 1;
					visibility: visible;
					transition:0.3s;
				}
				@include breakpoint(huge) {
					margin-top: 90px;
				}
			}
		}
	}
	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;
	}

	.videoImg {
		position: relative;
		.videoBox {
			.positionieren {
				position: absolute;
			    top: 50%;
			    left: 10%;
			    transform: translate(0, -50%);
			    display: flex;
    			align-items: center;
			    span {
			    	font-size: 30px;
			    	text-transform: uppercase;
			    	color:$light;
			    	font-weight: bold;
			    	text-shadow: 0 0 15px rgba(#000, 0.5);
			    	@include breakpoint(huge) {
			    		font-size: 65px;
			    		line-height: 75px;
			    	}
			    }
				i {
					width: 140px;
				    height: 137px;
				    margin: auto;
					background:transparent;
					border:10px solid $light;
					border-radius: 100%;
					animation: beat .6s infinite alternate;
					transform: scale(1);
					transition: 0.3s ease-in-out;
					cursor: pointer;
					font-size:120px;
					color:$light;
					text-shadow: 0 0 15px rgba(#000, 0.5);
					box-shadow: 0 0 15px rgba(#000, 0.5);
					
				
					@keyframes beat{
						to { transform: scale(0.8); }
					}
					&:hover {
						animation: beathover .6s forwards;
						border:10px solid $medium;
						@keyframes beathover{
							
							from { transform: scale(0.8); }
							to { transform: scale(1); }
						}
					}
				}
			}
		}
	}
	.paddingIt {
		padding:0 rem($baseGap)!important;
		margin-bottom: rem($baseGap*2);
	}
	.darkBgTeam {
		display: flex;
	    flex-wrap: wrap;
	    align-items: center;
	    .text {
	    	width: 100%;
	    }
		h3 {
			margin-bottom: 10px;
		}
		color:$dark;
		padding:rem($baseGap);
		body.stellenangeboteIndex & {
			border: 1px solid $thirdLight;
		}
		&:first-child {
			border-bottom: 1px solid $thirdLight;
			border-top: 1px solid $thirdLight;
			border-left: 1px solid $thirdLight;
		}
		&:last-child{
			border-bottom: 1px solid $thirdLight;
			border-top: 1px solid $thirdLight;
			border-right: 1px solid $thirdLight;
		}
		&.padding {
			padding:rem($baseGap*2);
			margin-bottom: rem($baseGap*2);
		}
		a{
			margin-bottom: 0;
		}

	}
	.marginBot {
		margin-bottom: 50px;
		@include breakpoint(giant) {
			margin-bottom: 100px;
		}
	}
	.leistungTeaser {
		&:hover {
			a{
				color: $light;
				.h3 {
					color: $light;
				}
			}
			.leistungenBg {
				background: $third;
				
			}
		}
		a {
			text-decoration: none!important;
			color: $dark;
			text-align: center;
			.h3 {
				letter-spacing: 0;
				text-decoration: none;
				font-weight: bold;
				margin-bottom: rem($baseGap);
			}
			&:hover{
				text-decoration: none;
			}
		}
		.leistungenBg {
			text-align: center;
			background: $thirdLight;
			transition: 0.3s;
			min-height: rem(300px);
			display: flex;
    		align-items: center;
    		justify-content: center;
    		padding: rem($baseGap);
    		@include breakpoint(giant) {
    			padding: rem($baseGap) rem($baseGap*2);
    		}
    		&.Bewerbungsformular {
    			display: none;
    		}
			
		}
	}
}



#privacyPolicy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}

// FOOTER
//////////////////////////////

#footer {
	padding-bottom: rem($baseGap);
	.ctctFooter {
		width: 100%;
		height: 100%;
		padding: 35px 0;
		text-align: center;
		color:$dark;
		span{
			b{
				display: block;
				text-transform: uppercase;
			}
			.breakIt {
				display: block;
				@include breakpoint(small) {
					display: inline;
				}
			}
		}
	}
	.googleMaps {
		padding-bottom: 460px;
		body.cmsBackend & {
			display: none;
		}
	}
	@include breakpoint(medium) {
		.ctctFooter {
			span{
				b{
					display: inline-block;
					&:after {
						content: '|';
						position: relative;
						font-weight: 300;
						padding: 0 10px;
					}
				}
			}
		}
	}
	@include breakpoint(huge) {
		.ctctFooter {
			.row {
				>.col {
					display: flex;
	    			justify-content: space-between;
				}
			}
	    }
	}
}