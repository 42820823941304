// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: large; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $light; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: 1em rem($baseGap);
	text-decoration: none;
	color: $navColor;
}
%buttonHover {
	// Link Hover
	color: $dark;
}
%buttonActive {
	// Link Active
	color: $dark;
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, arrow-down);
	transform: rotate(90deg);
	color:$thirdLight;

	@include breakpoint(large) {
		transform: rotate(0deg);
	}
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, arrow-down);
	transform: rotate(-90deg);
	color:$thirdLight;
	@include breakpoint(large) {
		transform: rotate(0deg);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 80px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $dark; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;
	.brandingDesktop {
		display: none;
	}
	nav {
		 max-width: 400px;
		.ctctNav {
			margin-top: rem($baseGap);
			.time {
				background: $primaryLight;
				color:$dark;
				width: 100%;
				cursor: default;
				.textMini {
					display: block;
					font-size: 14px;
					line-height: 22px;
				}
			}
			.mail {
				background: $secondaryLight; 
				color:$dark;
				width: 100%;
				&:hover {
					background: $secondary; 
				}
			}
			.tel {
				background: $thirdLight;
				color:$dark;
				width: 100%;
				&:hover {
					background: $third; 
				}
			}
			.btn {
				font-size: 14px;
				margin-bottom: rem($baseGap);
				i {
					margin-right: 5px;
					font-size: 18px;
				}
				@include breakpoint(tiny) {
					font-size: 18px;
				}
			}
		}
		.navigationMain	{
			@include ul {
				&.navi {
					li {
						&.team {
							display: none;
						}
						a, span.subToggler {
							position: relative;
							&:before {
								content: '';
								background:transparent;
								width: 40px;
								height: 40px;
								position: absolute;
								border-radius:100%; 
								left: 0;
								top:50%;
								transform: translateY(-50%);
								z-index: -1;
								transition:0.3s;
							}
						}
						&:hover {
							a, span.subToggler {
								&:before {
									background:$thirdLight;
								}
							}
						}
						&.active {
							a, span.subToggler {
								&:before {
									background:$third;
								}
							}
						}
						@include navButtons {
							@include navButton;
						}
						@include hasSub {
							@include sub {
								background: $thirdLight;
								li {
									a {
										transition: 0.3s;
										&:before {
											background:transparent;
										}
									}
									&:hover, &.active {
										a {
											background: $third;
											color:$light;
											&:before {
												background:transparent;
											}
										}
									}
								}
								&:before {
									padding: 1em rem($baseGap);
									background-color: darken($navBg, 6%);
								}
							}
						}
					}
				}
			}
			.socialIcon {
				display: flex;
				margin-bottom: 50px;
				margin-top: rem($baseGap);
				margin-left: rem($baseGap);
				a {
					img {
						width: 30px;
						height: 30px;
						transition:0.3s;
						margin-right: rem($baseGap);
					}
					&:hover {
						img {
							opacity: 0.5;
						}
					}
				}
			}
		}
	}
}


// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#navigation {
		@include breakpoint(giant) {
			padding: 20px 0;
			transition:0.3s;
		}
		&.scroll {
			padding:0;
			box-shadow: 0 0 15px rgba(#000, 0.5);
			.brandingDesktop {
				max-width: 0;
			    padding: 0;
			    line-height: 0;
			    @include breakpoint(giant) {
			    	max-width:320px;
					margin:auto;
					padding: rem($baseGap);
			    }
			}
			nav {
				.navigationMain	{
					ul {
						&.navi {
							li {
								&.hasSub {
									.sub {
										top: 100%;
									}
								}
							}
						}
					}	
				}
			}
		}
		nav {
			width: 100%;
			max-width: 100%;
			.brandingDesktop {
				display: block;
				max-width:300px;
				margin:auto;
				padding: rem($baseGap);
				transition:0.3s;
			}
			
			.ctctNav {
				display: flex;
				justify-content: center;
				.time{
					width: auto;
					margin-right: rem($baseGap);
					margin-bottom: 0;
					position: relative;
					.textMini {
						position: absolute;
						font-size: 12px;
						top:-20px;
					}
				}
				.mail {
					width: auto;
					margin-right: rem($baseGap);
					margin-bottom: 0;
				}
				.tel{
					width: auto;
					margin-bottom: 0;
				}
				.btn {
					padding: 10px;
				}
				@include breakpoint(giant) {
					justify-content: flex-end;
				}
			}
			.navigationMain	{
				width: 100%;
				display: flex;
				ul {
					&.navi {
						li {
							
							&.anfahrt, &.datenschutzerklaerung, &.impressum, &.kontakt, &.startseite, &.agbs {
								display: none;
							}
							&.hasSub {
								&:hover {
									.sub {
										opacity: 1;
										visibility: visible;
									}
								}
								.sub {
									transition: 0.3s;
									top: calc(100% + 7px);
								}
								
							}
							&.navBack {
								display: none;
							}
							@include breakpoint(giant) {
								a, span.subToggler {
									font-size: 16px;
								}
							}
							@include breakpoint(huge) {
								&.startseite {
									display: block;
								}
							}
							@include breakpoint(full) {
								a, span.subToggler {
									font-size: 18px;
								}
								&.kontakt {
									display: block;
								}
							}
						}
					}
				}
				.socialIcon {
					margin-left: 0;
					margin-bottom: 0;
					margin-top: rem($baseGap);
					@include breakpoint(giant) {
						margin-top: 10px;
						a {
							&:last-child {
								img {
									margin-right: 0;
								}
							}
							img {
								margin-top: 10px;
								width: 25px;
								height: 25px;
							}
						}
					}
					@include breakpoint(huge) {
						margin-top: rem($baseGap);
						a {
							img {
								margin-top: 0;
								width: 30px;
								height: 30px;
							}
						}
					}
					a {
						img {
							margin-right: rem($baseGap);
						}
					}
				}
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	@extend %animatedTransform;
	display: flex;
	justify-content: center;
	padding: rem($baseGap);
	z-index: 1000;
	width: 100%;
	left:50%;
	transform: translateX(-50%);
	.miniBar {
		width: 130px;
		height: 50px;
		background-color: $barBg;
		color: $barColor;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px rem($baseGap);
		border-radius: 50px;
	}

	@include breakpoint($break) {
		display: none;
	}
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////
.naviAdd {
	display: none;
	justify-content: center;
	
	li {
		margin-right: 2rem;
		a {
			color:$dark;
			text-decoration: none;
		}
		&.active {
			a {
				text-decoration: underline;
			}
		}
		&:hover {
			a {
				color:$primary;
			}
		}
	}
	@include breakpoint(large) {
		display: flex;
		margin-top: 20px;
	}
	@include breakpoint(huge) {
		margin-top: 0;
	}
}